import React from "react";
import styled from "styled-components";

const BannerDetail = (props) => {
  const { s_id, s_title, s_description, s_img_ext, s_img_data } = props;
  return (
    <div className="row text-center mt-5 align-items-center">
      <div className="col-12 col-md-4 mt-1 mt-md-0 s-img">
        <img src={`data:${s_img_ext};base64,${s_img_data}`} alt="" />
      </div>
      <div className="col-12 col-md-8 mt-1 mt-md-0 ">
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>
                <b>Título</b>
              </td>
              <td>{s_title}</td>
            </tr>
            <tr>
              <td>
                <b>Descripción</b>
              </td>
              <td>{s_description}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default BannerDetail;
