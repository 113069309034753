import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  WorkDetail,
  DeleteWork,
  WorkImages,
  AddNewItem,
  EditItem,
} from "../components";
import { host, port, t_Works, p_Works, routeWorks } from "../constants/db";

const ManageWorks = () => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [works, setWorks] = useState([]);
  const getAllWorks = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeWorks}`
      );
      const jsonData = await response.json();
      setWorks(jsonData);
    } catch (err) {
      setIsError(true);
      setErrorMessage(`Error: ${err.message}`);
    }
  };
  useEffect(() => {
    getAllWorks();
  }, []);
  const FormFields = [
    {
      for: "title",
      type: "text",
      label: "Título",
      placeholder: "",
      multiple: false,
    },
    {
      for: "description",
      type: "text",
      label: "Descripción",
      placeholder: "",
      multiple: false,
    },
    {
      for: "classification",
      type: "text",
      label: "Clasificación",
      placeholder: "",
      multiple: false,
    },
  ];
  const newEmptyWork = {
    title: "",
    description: "",
    classification: "",
  };
  return (
    <>
      <Wrapper>
        <div className="container">
          <Link to="/">Volver al Inicio</Link>
          <h1 className="text-center">Obras</h1>
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-primary ml-auto mb-1 px-2"
              onClick={() => getAllWorks()}
            >
              Volver a cargar
            </button>
          </div>

          <AddNewItem
            itemName="Obra"
            fields={FormFields}
            emptyNewItem={newEmptyWork}
            routeToAdd={routeWorks}
          />
          {isError && <h4 className="error-message">{errorMessage}</h4>}
          <hr />
          {!isError &&
            works.map((work) => {
              return (
                <React.Fragment key={work.w_id}>
                  <WorkDetail {...work} />
                  <div className="col-12 col-md-6 ml-auto text-right">
                    <EditItem
                      itemName="Obra"
                      itemId={work.w_id}
                      currentItem={work}
                      tableName={t_Works}
                      prefix={p_Works}
                      routeToEdit={routeWorks}
                      fields={FormFields}
                    />
                    <WorkImages routeGet={routeWorks} workId={work.w_id} />
                    &nbsp;
                    <DeleteWork
                      workId={work.w_id}
                      setIsError={setIsError}
                      setErrorMessage={setErrorMessage}
                    />
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: var(--clr-grey-9);
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  hr {
    border: var(--clr-grey-5) solid 0.11rem !important;
    background-color: var(--clr-grey-5);
  }
  .cover {
    img {
      max-width: 20rem;
      max-heigth: 20rem;
    }
  }
  /* .table td {
    border: var(--clr-grey-5) solid 0.11rem !important;
  } */
`;
export default ManageWorks;
