import React, { useState } from "react";
import { port, host } from "../constants/db";

const AddNewItem = (props) => {
  const { itemName, fields, emptyNewItem, routeToAdd } = props;
  const [newItem, setNewItem] = useState(emptyNewItem);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const multiple = e.target.getAttribute("data-mult");
    if (multiple === "true") {
      setNewItem({ ...newItem, [name]: value.split(",") });
    } else {
      setNewItem({ ...newItem, [name]: value });
    }
    // console.log(newItem);
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const body = newItem;
      if (body.telephoneNumbers) {
        body.telephoneNumbers = JSON.stringify(body.telephoneNumbers);
      }
      if (body.emails) {
        body.emails = JSON.stringify(body.emails);
      }

      console.log(`${process.env.REACT_APP_WS_BASE_URL}/${routeToAdd}`);
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeToAdd}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      // window.location = `/#/${routeToAdd}`;
      window.location.reload();
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <>
      <div className="col-12 mx-auto text-right">
        <button
          className="btn btn-success ml-auto"
          data-toggle="modal"
          data-target="#addItemModal"
        >
          Agregar {itemName}
        </button>
      </div>
      <div className="modal" id="addItemModal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Agregar {itemName}</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form className="form">
                {fields.map((field) => {
                  return (
                    <div className="form-group row" key={field.for}>
                      <label
                        htmlFor={field.for}
                        className="col-sm-4 col-form-label text-right"
                      >
                        {field.label}
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type={field.type}
                          id={field.for}
                          name={field.for}
                          value={newItem[field.for]}
                          data-mult={field.multiple}
                          onChange={handleChange}
                          placeholder={field.placeholder}
                        />
                      </div>
                    </div>
                  );
                })}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                Aceptar
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewItem;
