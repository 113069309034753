import React from "react";
import { port, host } from "../constants/db";

const DeleteItem = (props) => {
  const { itemId, tableName, prefix, route, itemName } = props;
  const deleteItem = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${prefix}-${itemId}-${tableName}`,
        { method: "DELETE" }
      );
      // if (route) window.location = `/#/${route}`;
      window.location.reload();
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <button className="btn btn-danger" onClick={() => deleteItem()}>
      Eliminar {itemName}
    </button>
  );
};

export default DeleteItem;
