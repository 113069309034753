import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Cookies from "universal-cookie";

const Login = (props) => {
  // const { setIsLogin } = props;
  const cookies = new Cookies();

  const [username, setUserName] = useState("");
  const [psw, setPsw] = useState("");

  const [authError, setAuthError] = useState({ status: false, message: "" });
  //   useEffect(() => {
  //     window.location = "/#";
  //   }, []);

  const requestLogin = async () => {
    setAuthError({ status: false, message: "" });
    const body = {
      username: username,
      psw: psw,
    };
    const response = await fetch(`${process.env.REACT_APP_WS_BASE_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    if (response.status === 401) {
      setAuthError({ status: true, message: "Ingreso no autorizado" });
      return;
    }
    if (response.status === 200) {
      cookies.set("lin", "1", { maxAge: 3600 });
      window.location.reload();
      // setIsLogin(true);
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      requestLogin();
    }
  };
  return (
    <Root>
      <h2>Iniciar Sesión</h2>
      <form>
        <input
          type="text"
          id="login"
          placeholder="Usuario"
          value={username}
          onInput={(e) => {
            setAuthError(false);
            setUserName(e.target.value);
          }}
          onKeyPress={handleKeypress}
        />
        <input
          type="password"
          id="password"
          placeholder="Contraseña"
          value={psw}
          onInput={(e) => {
            setAuthError(false);
            setPsw(e.target.value);
          }}
          onKeyPress={handleKeypress}
        />
        <input
          type="submit"
          value="Iniciar Sesión"
          onClick={() => {
            requestLogin();
          }}
        />
        {authError.status && <h6 className="error">{authError.message}</h6>}
      </form>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
  padding: 20px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    input[type="text"],
    input[type="password"] {
      background-color: #f6f6f6;
      border: none;
      color: #0d0d0d;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 5px;
      width: 85%;
      border: 2px solid #f6f6f6;
      transition: all 0.5s ease-in-out;
      border-radius: 5px 5px 5px 5px;
    }
    input[type="text"]:focus,
    input[type="password"]:focus {
      background-color: #fff;
      border-bottom: 2px solid #5fbae9;
    }
    input[type="text"],
    input[type="password"]:placeholder {
      color: #cccccc;
    }

    input[type="button"],
    input[type="submit"],
    input[type="reset"] {
      width: 60%;
      background-color: #56baed;
      border: none;
      color: white;
      padding: 1em 2em;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      text-transform: uppercase;
      font-size: 13px;
      box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.2);
      border-radius: 5px 5px 5px 5px;
      margin: 5px 20px 40px 20px;
      transition: all 0.3s ease-in-out;
    }
    input[type="button"]:hover,
    input[type="submit"]:hover,
    input[type="reset"]:hover {
      background-color: #39ace7;
    }
    input[type="button"]:active,
    input[type="submit"]:active,
    input[type="reset"]:active {
      background-color: #1374a5;
    }
  }
  *:focus {
    outline: none;
  }
`;
export default Login;
