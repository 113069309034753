import React from "react";
import {
  host,
  port,
  t_WorksImages,
  p_Works,
  routeWorks,
} from "../constants/db";
import styled from "styled-components";
import { DeleteItem, UploadMultipleImages } from ".";

class WorkImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: {},
      isError: false,
      errorMessage: "",
    };
  }
  getImagesOfWork = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeWorks}/${this.props.workId}`
      );
      const jsonData = await response.json();
      this.setState({ images: jsonData });
    } catch (err) {
      console.error(err.message);
    }
  };

  render() {
    return (
      <>
        <button
          className="btn btn-info ml-2"
          data-toggle="modal"
          href={`#modal-${this.props.workId}`}
          onClick={() => {
            if (this.state.images.length > 0) {
            } else {
              this.getImagesOfWork();
            }
          }}
        >
          Administrar Imágenes
        </button>
        <WorkModal
          images={this.state.images}
          w_id={this.props.workId}
          imagesLength={this.state.images.length}
          getImagesOfWork={this.getImagesOfWork}
        />
      </>
    );
  }
}

const WorkModal = (props) => {
  const { images, w_id, imagesLength, getImagesOfWork } = props;
  const handleChangeCover = async (e, workImageId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeWorks}?w_id=${w_id}&wi_id=${workImageId}`,
        { method: "PUT" }
      );
      console.log(await response.json);
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <div className="modal" id={`modal-${w_id}`}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4>Imágenes de la Obra</h4>
            <button className="close fa fa-close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body px-5">
            <Wrapper className="row justify-content-center">
              {imagesLength > 0 &&
                images.map((im, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-5 text-center my-1 mx-1">
                        <img
                          src={`data:image/${im.wi_extension};base64,${im.wi_data}`}
                          alt={im.wi_data}
                          className="mb-2"
                        />
                        <label className="form-check-label btn">
                          {im.wi_cover === 1 ? (
                            <input
                              type="radio"
                              className="form-check-input"
                              name="cover"
                              defaultChecked
                              onChange={(e) => handleChangeCover(e, im.wi_id)}
                            />
                          ) : (
                            <input
                              type="radio"
                              className="form-check-input"
                              name="cover"
                              onChange={(e) => handleChangeCover(e, im.wi_id)}
                            />
                          )}
                          Portada
                        </label>
                        <DeleteItem
                          itemId={im.wi_id}
                          tableName={t_WorksImages}
                          prefix="wi"
                          itemName={"Imagen"}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              <div className="col-12 text-center">
                <UploadMultipleImages
                  tableName={t_WorksImages}
                  route="upMult"
                  workId={w_id}
                />
              </div>
            </Wrapper>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => getImagesOfWork()}
            >
              Volver a cargar
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const Wrapper = styled.div`
  .col-5 {
    padding: 1rem;
    background-color: var(--clr-grey-9);
    -webkit-box-shadow: var(--light-shadow);
    box-shadow: var(--light-shadow);
    border-radius: var(--radius);
    height: auto;
    min-height: 25rem;
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 20rem;
    object-fit: contain;
    -o-object-fit: contain;
    text-align: center !important;
  }
`;
export default WorkImages;
