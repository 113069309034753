import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  port,
  routeServices,
  host,
  t_Services,
  p_Services,
} from "../constants/db";
import {
  BannerDetail,
  AddNewItem,
  DeleteItem,
  EditItem,
  ServiceImage,
} from "../components";

import styled from "styled-components";

const ManageServicesCarousel = () => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [banners, setBanners] = useState([]);
  const [newBanner, setNewBanner] = useState({
    s_title: "",
    s_description: "",
    s_img_ext: "",
    s_img_data: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewBanner({ ...newBanner, [name]: value });
  };
  const getAllBanners = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/table/${t_Services}`
      );
      const jsonData = await response.json();
      setBanners(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  const deleteBanner = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${id}`,
        {
          method: "DELETE",
        }
      );
      /* const response = await fetch(
        `http://${host}:${port}/${routeServicesBanners}/${id}`,
        { method: "DELETE" }
      ); */
      setBanners(banners.filter((banner) => banner.s_id !== id));
      getAllBanners();
    } catch (error) {
      console.error(error.message);
    }
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const body = newBanner;
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeServices}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      // window.location = "/#/servicesCarousel";
      window.location.reload();
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getAllBanners();
    // console.log(banners);
  }, []);
  const FormFields = [
    {
      for: "title",
      type: "text",
      label: "Título",
      placeholder: "",
      multiple: false,
    },
    {
      for: "description",
      type: "text",
      label: "Descripción",
      placeholder: "",
      multiple: false,
    },
  ];
  const newEmptyService = {
    title: "",
    description: "",
  };
  return (
    <>
      <Wrapper>
        <div className="container">
          <Link to="/">Volver al Inicio</Link>
          <h1 className="text-center">Servicios</h1>
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-primary ml-auto mb-1 px-2"
              onClick={() => getAllBanners()}
            >
              Volver a cargar
            </button>
          </div>
          <AddNewItem
            itemName="Servicio"
            fields={FormFields}
            emptyNewItem={newEmptyService}
            routeToAdd={routeServices}
          />
          {/* <div className="col-12 mx-auto text-right">
            <button
              className="btn btn-success ml-auto"
              data-toggle="modal"
              data-target="#addServiceModal"
            >
              Agregar Servicio
            </button>
          </div> */}
          {isError && <h4 className="error-message">{errorMessage}</h4>}
          <hr />
          {!isError &&
            banners.map((banner) => {
              return (
                <React.Fragment key={banner.s_id}>
                  <BannerDetail {...banner} />
                  <div className="col-12 col-md-6 ml-auto text-right ">
                    {/* <button
                      className="btn btn-warning"
                      onClick={() => {
                        // deleteBanner(banner.s_id)
                      }}
                    >
                      Editar Servicio
                    </button> */}
                    <ServiceImage
                      serviceId={banner.s_id}
                      serviceImgExt={banner.s_img_ext}
                      serviceImgData={banner.s_img_data}
                      tableName={t_Services}
                      routeServices={routeServices}
                    />
                    <EditItem
                      itemName="Servicio"
                      itemId={banner.s_id}
                      currentItem={banner}
                      tableName={t_Services}
                      prefix={p_Services}
                      routeToEdit={`${routeServices}Details`}
                      fields={FormFields}
                    />
                    &nbsp;
                    <DeleteItem
                      itemId={banner.s_id}
                      tableName={t_Services}
                      prefix={p_Services}
                      route={routeServices}
                      itemName="Servicio"
                    />
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => {
                        deleteBanner(banner.s_id);
                      }}
                    >
                      Eliminar Servicio
                    </button> */}
                  </div>

                  <hr />
                </React.Fragment>
              );
            })}
        </div>
        {/* <div className="modal" id="addServiceModal">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Agregar Servicio</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <form className="form">
                  <div className="form-group row">
                    <label htmlFor="title" className="col-sm-4 col-form-label">
                      Título:
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        id="title"
                        name="title"
                        value={newBanner.place}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="linkGoogleMaps"
                      className="col-sm-4 col-form-label"
                    >
                      Link de Google Maps:
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        id="linkGoogleMaps"
                        name="linkGoogleMaps"
                        value={newBanner.linkGoogleMaps}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="address"
                      className="col-sm-4 col-form-label"
                    >
                      Dirección:
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        id="address"
                        name="address"
                        value={newBanner.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="telephoneNumbers"
                      className="col-sm-4 col-form-label"
                    >
                      Números de Teléfono:
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        id="telephoneNumbers"
                        name="telephoneNumbers"
                        value={newBanner.telephoneNumbers}
                        onChange={handleChange}
                        placeholder={
                          "Utilizar , para cargar varios: +595123456,+5951236549"
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="email" className="col-sm-4 col-form-label">
                      Emails:
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        id="email"
                        name="email"
                        value={newBanner.email}
                        onChange={handleChange}
                        placeholder={
                          "Utilizar , para cargar varios: email1@gmail.com,email2@gmail.com"
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={handleSubmit}
                >
                  Aceptar
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: var(--clr-grey-9);
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  hr {
    border: var(--clr-grey-5) solid 0.11rem !important;
    background-color: var(--clr-grey-5);
  }
  /* .table td {
    border: var(--clr-grey-5) solid 0.11rem !important;
  } */
  .s-img {
    img {
      max-width: 20rem;
      max-height: 20rem;
    }
  }
`;
export default ManageServicesCarousel;
