import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { port, host } from "../constants/db";

const UploadOneImage = (props) => {
  const { tableName, _id, route } = props;
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [base64TextString, setBase64TextString] = useState("");
  const [imageType, setImageType] = useState("");
  const [noErrors, setNoErrors] = useState(false);

  useEffect(() => {
    setBase64TextString("");
  }, []);
  const handleFormChange = (e) => {
    if (disableSubmit) setDisableSubmit(false);
    setNoErrors(false);
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded;
      reader.readAsBinaryString(file);
      setImageType(file.type);
    }
  };
  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64TextString(btoa(binaryString));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        tableName: tableName,
        extension: imageType,
        data: base64TextString,
      };
      // console.log(`${process.env.REACT_APP_WS_BASE_URL}/${route}/${_id}`);
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${route}/${_id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      setNoErrors(true);
      // window.location = `/${route}`;
      window.location.reload();
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      <Wrapper>
        {base64TextString && (
          <div>
            <img
              src={`data:${imageType};base64,${base64TextString}`}
              alt="FirstImage"
            />
          </div>
        )}
        <form
          onChange={(e) => {
            handleFormChange(e);
          }}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="mt-2"
        >
          <input
            type="file"
            name="image"
            id="file"
            accept=".jpeg, .jpg, .png"
          />
          {disableSubmit ? (
            <button className="btn btn-primary" type="submit" disabled>
              Subir
            </button>
          ) : (
            <button className="btn btn-primary" type="submit">
              Subir
            </button>
          )}
          {noErrors && <div>Subido correctamente</div>}
        </form>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  img {
    max-width: 50vw;
    max-heigth: 50vh;
  }
`;

export default UploadOneImage;
