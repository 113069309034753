export const port = 5800;
export const t_Contact = "ContactDetails";
export const p_Contact = "c";
export const routeContact = "contactDetails";

export const t_Clients = "ClientsLogos";
export const p_Clients = "cl";
export const routeClients = "clients";

export const t_Works = "Works";
export const p_Works = "w";
export const routeWorks = "works";

export const t_WorksImages = "WorksImages";
export const p_WorksImages = "wi";

export const host = "181.126.89.13";
// export const host = "192.168.0.7";
// export const host = "192.168.100.225";
// export const host = "localhost";

export const t_HomeImages = "HomeImages";
export const routeHomeImages = "HomeImages";

export const t_Services = "servicesDetails";
export const p_Services = "s";
export const routeServices = "servicesCarousel";
