import React from "react";

const OfficeDetail = (props) => {
  const { c_place, c_linkGoogleMaps, c_telephoneNumbers, c_address, c_emails } =
    props;

  return (
    <div className="row text-center mt-5 align-items-center">
      <div className="col-12 col-md-4 mt-1 mt-md-0 map">
        <iframe src={c_linkGoogleMaps} title={`map-${c_place}`}></iframe>
      </div>
      <div className="col-12 col-md-8 mt-1 mt-md-0 ">
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <td>
                <b>Lugar</b>
              </td>
              <td>{c_place}</td>
            </tr>
            <tr>
              <td>
                <b>Dirección</b>
              </td>
              <td>{c_address}</td>
            </tr>
            <tr>
              <td>
                <b>Teléfonos</b>
              </td>
              <td>
                {JSON.parse(c_telephoneNumbers).map((tel) => {
                  return (
                    <React.Fragment key={tel}>
                      {tel} <br />
                    </React.Fragment>
                  );
                })}
              </td>
            </tr>
            <tr>
              <td>
                <b>Emails</b>
              </td>
              <td>
                {JSON.parse(c_emails).map((em) => {
                  return (
                    <React.Fragment key={em}>
                      {em}
                      <br />
                    </React.Fragment>
                  );
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfficeDetail;
