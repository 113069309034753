import React, { useState } from "react";
import { UploadOneImage } from "../components";

const ServiceImage = (props) => {
  const [image, setImage] = useState({});
  const {
    serviceId,
    serviceImgExt,
    serviceImgData,
    tableName,
    routeServices,
  } = props;
  return (
    <>
      <button
        className="btn btn-info mr-1"
        data-toggle="modal"
        href={`#modal-${serviceId}`}
      >
        Cambiar Imagen
      </button>
      <ServiceImageModal
        serviceId={serviceId}
        serviceImgExt={serviceImgExt}
        serviceImgData={serviceImgData}
        tableName={tableName}
        routeServices={routeServices}
      />
    </>
  );
};

const ServiceImageModal = (props) => {
  const {
    serviceId,
    serviceImgExt,
    serviceImgData,
    tableName,
    routeServices,
  } = props;
  return (
    <div className="modal" id={`modal-${serviceId}`}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4>Imagen del Servicio</h4>
            <button className="close fa fa-close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body px-5">
            <div className="col-12 text-center">
              <UploadOneImage
                tableName={tableName}
                _id={serviceId}
                route={routeServices}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceImage;
