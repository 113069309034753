import React from "react";
import { host, port, routeWorks } from "../constants/db";

const DeleteWork = (props) => {
  const { workId, setIsError, setErrorMessage } = props;
  const deleteWork = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeWorks}/${workId}`,
        { method: "DELETE" }
      );
      // window.location = "/#/works";
      window.location.reload();
    } catch (err) {
      setIsError(true);
      setErrorMessage(`Error: ${err.message}`);
    }
  };
  return (
    <button className="btn btn-danger" onClick={deleteWork}>
      Eliminar Obra
    </button>
  );
};

export default DeleteWork;
