import React, { useState, useEffect } from "react";
import { port, host } from "../constants/db";

import styled from "styled-components";

const UploadMultipleImages = (props) => {
  const { tableName, route, workId } = props;
  const [disableSubmit, setDisableSubmit] = useState(true);

  /*Handling image upload */
  const [base64TextString, setBase64TextString] = useState("");
  const [imageType, setImageType] = useState("");
  const [filesUp, setFilesUp] = useState([]);
  const [displayImg, setDisplayImg] = useState(false);

  const [noErrors, setNoErrors] = useState(false);

  const handleFormChange = async (e) => {
    if (disableSubmit) setDisableSubmit(false);
    setNoErrors(false);
    var files = e.target.files;
    for (const f of Array.from(files)) {
      let content = await readFileAsync(f);
      filesUp.push({ extension: f.type, data: content });
      setFilesUp(filesUp);
    }
    setDisplayImg(true);
    // console.log(filesUp);
    try {
    } catch (error) {
      console.error(error.message);
    }
  };
  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(btoa(reader.result));
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  }

  /*handling submit*/
  const handleSubmit = async (e) => {
    // console.log(imageType);
    e.preventDefault();
    try {
      const body = {
        tableName: tableName,
        filesUp: filesUp,
      };
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${route}/${workId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      setNoErrors(true);
      setFilesUp([]);
    } catch (error) {
      console.error(error.message);
    }
    //console.log(imageType, base64TextString);
    /* const entries = new Map([
      ["tableName", tableName],
      ["extension", imageType],
      ["data", base64TextString],
    ]);
    const obj = Object.fromEntries(entries);
    console.log(obj); */
    /* try {
      base64TextString.forEach(async (v, i) => {
        const body = { tableName: tableName, extension: imageType[i], data: v };
        // console.log(body);
        const response = await fetch(`http://${host}:${port}/${route}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        });
      });
      setNoErrors(true);
      window.location("");
    } catch (error) {
      console.log(error.message);
    } */

    // images tiene que ser un array con objetos
    /* [
      {
        extension: "jpg",
        data: "",
      },
    ]; */

    // console.log(base64TextString);
  };
  return (
    <Wrapper>
      <form
        onChange={(e) => {
          handleFormChange(e);
        }}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className="mt-2"
      >
        <input
          type="file"
          name="image"
          /* id="file" */
          accept=".jpeg, .jpg, .png"
          multiple
        />
        {disableSubmit ? (
          <button className="btn btn-primary" type="submit" disabled>
            Subir
          </button>
        ) : (
          <button className="btn btn-primary" type="submit">
            Subir
          </button>
        )}
        {noErrors && <div>Subido correctamente</div>}
      </form>
      <div className="row">
        {displayImg || (
          <>
            {filesUp.map((v, i) => {
              return (
                <div className="col-6" key={i}>
                  hola
                  <img
                    src={`data:${v.extension};base64,${v.data}`}
                    alt="hola"
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div``;
export default UploadMultipleImages;
