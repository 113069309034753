import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DeleteItem } from "../components";
import {
  port,
  t_Clients,
  p_Clients,
  host,
  routeClients,
} from "../constants/db";

const ManageClients = () => {
  const [clients, setClients] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const getAllClients = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/table/${t_Clients}`
      );
      const jsonData = await response.json();
      setClients(jsonData);
    } catch (error) {
      setIsError(true);
      setErrorMessage(`Error: ${error.message}`);
    }
  };
  useEffect(() => {
    getAllClients();
  }, []);

  const [newClient, setNewClient] = useState({
    cl_name: "",
    cl_extension: "",
    cl_data: "",
  });
  const [imageType, setImageType] = useState("");
  const [base64TextString, setBase64TextString] = useState("");
  const [noErrors, setNoErrors] = useState(false);
  const [displayImg, setDisplayImg] = useState(false);

  const handleChangeText = (e) => {
    setNoErrors(false);
    setNewClient({ ...newClient, cl_name: e.target.value });
  };
  const handleChangeImage = async (e) => {
    setNoErrors(false);
    let file = e.target.files[0];
    if (file) {
      let content = await readFileAsync(file);
      /* const reader = new FileReader();
      reader.onload = _handleReaderLoaded;
      reader.readAsBinaryString(file); */
      setNewClient({
        ...newClient,
        cl_extension: file.type,
        cl_data: content,
      });
      setDisplayImg(true);
    }
  };
  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(btoa(reader.result));
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
      setImageType(file.type);
    });
  }
  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64TextString(btoa(binaryString));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = newClient;
    const response = await fetch(
      `${process.env.REACT_APP_WS_BASE_URL}/${routeClients}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }
    );
    setNoErrors(true);
    setNewClient({});
    // window.location = "/#/clients";
    window.location.reload();
  };

  return (
    <>
      <Wrapper>
        <div className="container">
          <Link to="/">Volver al Inicio</Link>
          <h1 className="text-center">Clientes</h1>
          <div className="col-12 text-right">
            <button
              className="btn btn-success"
              data-toggle="modal"
              data-target="#addClientModal"
            >
              Agregar Cliente
            </button>
          </div>

          {isError && <h4 className="error-message">{errorMessage}</h4>}
          <hr />
          {!isError && (
            <div className="row">
              {clients.map((client) => {
                return (
                  <React.Fragment key={client.cl_id}>
                    <div className="col-6 col-md-4 text-center mt-2">
                      <img
                        src={`data:${client.cl_extension};base64,${client.cl_data}`}
                        alt={client.cl_name}
                      />
                      <div className="row mt-2">
                        <div className="col-6 mx-auto">
                          <DeleteItem
                            itemId={client.cl_id}
                            tableName={t_Clients}
                            prefix={p_Clients}
                            route={routeClients}
                            itemName={"Cliente"}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </Wrapper>
      <div className="modal" id="addClientModal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Agregar Cliente</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form className="form">
                <div className="form-group row">
                  <label
                    htmlFor="cl_name"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Nombre*
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="text"
                      id="cl_name"
                      name="cl_name"
                      value={newClient["cl_name"]}
                      onChange={handleChangeText}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="cl_name"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Logo*
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="file"
                      name="image"
                      id="file"
                      accept=".jpeg, .jpg, .png"
                      onChange={handleChangeImage}
                    />
                  </div>
                  <div className="col-sm-8 ml-auto">
                    {displayImg && (
                      <div>
                        <img
                          src={`data:${newClient.cl_extension};base64,${newClient.cl_data}`}
                          alt="NewClientLogo"
                          className="preview-img"
                          style={{ maxHeight: "20vh", maxWidth: "20vw" }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="text-right">
                  *Los campos deben estar completos
                </div>

                {noErrors && <div>Subido correctamente</div>}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                Aceptar
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Wrapper = styled.div`
  background-color: var(--clr-grey-9);
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  hr {
    border: var(--clr-grey-5) solid 0.11rem !important;
    background-color: var(--clr-grey-5);
  }
  img {
    height: 10rem;
  }
`;
export default ManageClients;
