import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import { OfficeDetail, AddNewItem, DeleteItem, EditItem } from "../components";
import {
  port,
  t_Contact,
  p_Contact,
  host,
  routeContact,
} from "../constants/db";

const ManageContactDetails = () => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [offices, setOffices] = useState([]);

  const getAllOffices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/table/${t_Contact}`
      );
      const jsonData = await response.json();
      setOffices(jsonData);
    } catch (error) {
      setIsError(true);
      setErrorMessage(`Error: ${error.message}`);
    }
  };
  useEffect(() => {
    getAllOffices();
  }, []);
  const FormFields = [
    {
      for: "place",
      type: "text",
      label: "Lugar",
      placeholder: "",
      multiple: false,
    },
    {
      for: "linkGoogleMaps",
      type: "text",
      label: "Link de Google Maps",
      placeholder: "",
      multiple: false,
    },
    {
      for: "address",
      type: "text",
      label: "Dirección",
      placeholder: "",
      multiple: false,
    },
    {
      for: "telephoneNumbers",
      type: "text",
      label: "Números de Teléfono",
      placeholder: "Utilizar , para cargar varios: +595123456,+5951236549",
      multiple: true,
    },
    {
      for: "emails",
      type: "text",
      label: "Emails",
      placeholder:
        "Utilizar , para cargar varios: email1@gmail.com,email2@gmail.com",
      multiple: true,
    },
  ];
  const newEmptyOffice = {
    place: "",
    linkGoogleMaps: "",
    telephoneNumbers: "",
    address: "",
    emails: "",
  };
  return (
    <>
      <Wrapper>
        <div className="container">
          <Link to="/">Volver al Inicio</Link>
          <h1 className="text-center">Oficinas</h1>
          <AddNewItem
            itemName="Oficina"
            fields={FormFields}
            emptyNewItem={newEmptyOffice}
            routeToAdd={routeContact}
          />

          {isError && <h4 className="error-message">{errorMessage}</h4>}
          <hr />

          {!isError &&
            offices.map((office) => {
              return (
                <React.Fragment key={office.c_id}>
                  <OfficeDetail {...office} />
                  <div className="col-12 col-md-6 ml-auto text-right">
                    <EditItem
                      itemName="Oficina"
                      itemId={office.c_id}
                      currentItem={office}
                      tableName={t_Contact}
                      prefix={p_Contact}
                      routeToEdit={routeContact}
                      fields={FormFields}
                    />
                    &nbsp;
                    <DeleteItem
                      itemId={office.c_id}
                      tableName={t_Contact}
                      prefix={p_Contact}
                      route={routeContact}
                      itemName={"Oficina"}
                    />
                  </div>
                  <hr />
                </React.Fragment>
              );
            })}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: var(--clr-grey-9);
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  /* .table td {
    border: var(--clr-grey-5) solid 0.11rem !important;
  } */
  hr {
    border: var(--clr-grey-5) solid 0.11rem !important;
    background-color: var(--clr-grey-5);
  }
  .map {
    width: 95%;
    height: 20rem;
    /* padding-left: 3rem;
    padding-right: 3rem; */
    background-color: var(--clr-grey-8);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    iframe {
      border: 0;
      allow-fullscreen: "";
      aria-hidden: "false";
      tab-index: "0";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (min-width: 992px) {
    width: 100%;
  }
`;

export default ManageContactDetails;
