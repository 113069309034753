import React, { useState } from "react";
import { port, host } from "../constants/db";

const EditItem = (props) => {
  const {
    itemName,
    itemId,
    currentItem,
    tableName,
    prefix,
    routeToEdit,
    fields,
  } = props;
  const [item, setItem] = useState(currentItem);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(value);
    const multiple = e.target.getAttribute("data-mult");
    if (multiple === "true") {
      setItem({ ...item, [name]: value.split(",") });
    } else {
      setItem({ ...item, [name]: value });
    }
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const body = item;
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeToEdit}/${itemId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      window.location.reload();
      // window.location = `/${routeToEdit}`;
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <>
      <button
        className="btn btn-warning"
        data-toggle="modal"
        data-target={`#EditItemModal-${itemId}`}
      >
        Editar {itemName}
      </button>
      <div className="modal" id={`EditItemModal-${itemId}`}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Editar {itemName}</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form className="form">
                {fields.map((field) => {
                  return (
                    <div className="form-group row" key={field.for}>
                      <label
                        htmlFor={field.for}
                        className="col-sm-4 col-form-label"
                      >
                        {field.label}
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type={field.type}
                          id={`${prefix}_${field.for}`}
                          name={`${prefix}_${field.for}`}
                          value={item[`${prefix}_${field.for}`]}
                          data-mult={field.multiple}
                          onChange={handleChange}
                          placeholder={field.placeholder}
                        />
                      </div>
                    </div>
                  );
                })}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                Aceptar
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditItem;
