import React from "react";
import { Link } from "react-router-dom";
import { UploadMultipleImages } from "../components";

import styled from "styled-components";
import Cookies from "universal-cookie";

const HomePage = ({ setIsLogin }) => {
  const cookies = new Cookies();
  return (
    <>
      <Wrapper>
        <div className="container pt-5">
          <h2 className="text-center">
            Sistema de Mantenimiento de Sitio Web - Intech SRL
          </h2>
          <div className="row">
            <div className="col-12 col-md-5 mx-auto mt-3">
              <Link to="/homeImages" className="btn btn-primary btn-block">
                Administrar Imágenes de Página de Inicio
              </Link>
              <Link
                to="/servicesCarousel"
                className="btn btn-primary btn-block"
              >
                Administrar Servicios
              </Link>
              <Link to="/clients" className="btn btn-primary btn-block">
                Administrar Clientes
              </Link>
              <Link to="/works" className="btn btn-primary btn-block">
                Administrar Galerías de Obras
              </Link>
              <Link to="/contactDetails" className="btn btn-primary btn-block">
                Administrar Página de Contacto
              </Link>
              <button
                className="btn btn-danger btn-block"
                onClick={() => {
                  cookies.remove("lin");
                  window.location.reload();
                  // setIsLogin(false);
                }}
              >
                Cerrar Sesión
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: var(--clr-grey-9);
  margin: 0;
  min-height: 100vh;
`;

export default HomePage;
