import React from "react";

const WorkDetail = (props) => {
  const {
    w_id,
    w_title,
    w_description,
    w_classification,
    wi_extension,
    wi_data,
  } = props;
  return (
    <div className="row text-center mt-5 align-items-center">
      <div className="col-12-col-md-4-mt-1 mt-md-0 cover">
        <img
          src={`data:${wi_extension};base64,${wi_data}`}
          alt={`${w_title}-cover`}
        />
      </div>
      <div className="col-12 col-md-8 mt-1 mt-md-0 ">
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <td>
                <b>Título</b>
              </td>
              <td>{w_title}</td>
            </tr>
            <tr>
              <td>
                <b>Descripción</b>
              </td>
              <td>{w_description}</td>
            </tr>
            <tr>
              <td>
                <b>Clasificación</b>
              </td>
              <td>{w_classification}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkDetail;
