import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";

import {
  ManageContactDetails,
  HomePage,
  ManageHomePageImages,
  ManageServicesCarousel,
  ManageClients,
  ManageWorks,
} from "./pages";
import React, { useEffect, useState } from "react";

import Cookies from "universal-cookie";

import Login from "./pages/Login";

function App() {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setIsLogin(cookies.get("lin") === "1");
    console.log(isLogin);
  });

  const cookies = new Cookies();

  return (
    <>
      <HashRouter>
        {isLogin ? (
          <Switch>
            <Route exact path="/">
              <HomePage setIsLogin={setIsLogin} />
            </Route>
            <Route exact path="/contactDetails">
              <ManageContactDetails />
            </Route>
            <Route exact path="/homeImages">
              <ManageHomePageImages />
            </Route>
            <Route exact path="/servicesCarousel">
              <ManageServicesCarousel />
            </Route>
            <Route exact path="/clients">
              <ManageClients />
            </Route>
            <Route exact path="/works">
              <ManageWorks />
            </Route>
          </Switch>
        ) : (
          <Login setIsLogin={setIsLogin} />
        )}
      </HashRouter>
    </>
  );
}

export default App;
