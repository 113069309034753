import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { port, host, routeHomeImages, t_HomeImages } from "../constants/db";
import { UploadOneImage } from "../components";

const ManageHomePageImages = () => {
  const tableName = "HomeImages";
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [images, setImages] = useState([]);
  const [update, setUpdate] = useState([false]);

  const getAllImages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeHomeImages}`
      );
      const jsonData = await response.json();
      setImages(jsonData);
    } catch (error) {
      setIsError(true);
      setErrorMessage(`Error: ${error.message}`);
    }
  };
  useEffect(() => {
    getAllImages();
    setUpdate(false);
  }, [update]);

  return (
    <>
      <Wrapper>
        <div className="container">
          <Link to="/">Volver al Inicio</Link>
          <h1 className="text-center">Imágenes - Página de Inicio</h1>
          <hr />
          {isError && <h4 className="error-message">{errorMessage}</h4>}
          {!isError && (
            <div className="row">
              {images.map((im) => {
                return (
                  <div
                    className="col-12 col-md-6 text-center"
                    key={im.hi_img_id}
                  >
                    <img
                      src={`data:${im.hi_extension};base64,${im.hi_data}`}
                      alt=""
                    />
                    <button
                      className="btn btn-primary btn-block mx-auto mt-1"
                      type="button"
                      data-toggle="modal"
                      data-target={`#updateImage-${im.hi_img_id}`}
                    >
                      Cambiar imagen
                    </button>
                    <div className="modal" id={`updateImage-${im.hi_img_id}`}>
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Actualizar imagen</h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div className="modal-body">
                            <UploadOneImage
                              tableName={tableName}
                              _id={im.hi_img_id}
                              route={routeHomeImages}
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                              onClick={() => {
                                /* window.location = "/homeImages"; */
                                setUpdate(!update);
                              }}
                            >
                              Cerrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* <form
            onChange={(e) => {
              handleFormChange(e);
            }}
          >
            <input
              type="file"
              name="image"
              id="file"
              accept=".jpeg, .jpg, .png"
              multiple
            />
            <input type="submit" />
          </form>*/}
        </div>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  background-color: var(--clr-grey-9);
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  hr {
    border: var(--clr-grey-5) solid 0.11rem !important;
    background-color: var(--clr-grey-5);
  }
  img {
    width: 100%;
  }
`;
export default ManageHomePageImages;
